import React, { useRef, useEffect, useState } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import styled from "styled-components"

import ContactSection from "../components/ContactSection"
import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutPageData from "../../site/pages/about_page.json"
import { setScrollAnimation } from "../helpers/animations"

const MainText = styled.p`
  @media (min-width: 62em) {
    -moz-column-count: 2;
    -moz-column-gap: var(--base-grid-gutter);
    -webkit-column-count: 2;
    -webkit-column-gap: var(--base-grid-gutter);
    column-count: 2;
    column-gap: var(--base-grid-gutter);
  }
`
export const BorderContainerBox = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 4rem;

  & > * {
    border-top: 2px solid var(--black);
    margin: 0;
    padding: 2rem 1.2rem 2rem 0;

    &:nth-child(2) {
      padding-left: 1.2rem;
      border-left: 1px solid var(--black);
    }
  }
`

const CooperatorName = styled.p`
  font-family: var(--fontFamily-titles);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.09rem;
  text-transform: uppercase;
  flex: 3 0 100px;
`
const CooperatorRole = styled.p`
  flex: 2 0 100px;
`
const AboutPage = ({ location }) => {
  gsap.registerPlugin(ScrollTrigger)
  const clearScrollAnimation = useRef(null)
  let [locoScroll, setLocoScroll] = useState(null)
  const sectionsRefs = useRef([])
  sectionsRefs.current = []

  const addToSectionsRefs = el => {
    if (el && !sectionsRefs.current.includes(el)) {
      sectionsRefs.current.push(el)
    }
  }

  const addLocomotiveScripts = () => {
    let localLocoScroll

    localLocoScroll = new window.LocomotiveScroll({
      el: document.querySelector(".scroll-container"),
      smooth: true,
      scrollFromAnywhere: true,
      reloadOnContextChange: true,
      // smartphone: {smooth: true},
      // tablet: {smooth: true}
    })

    setLocoScroll(localLocoScroll)

    clearScrollAnimation.current = setScrollAnimation(
      localLocoScroll,
      sectionsRefs
    )
  }

  useEffect(() => {
    return () => {
      clearScrollAnimation.current()
    }
  }, [])

  return (
    <Layout
      location={location}
      className="scroll-container"
      locoScroll={locoScroll}
      addLocomotiveScripts={addLocomotiveScripts}
    >
      <SEO
        title="Bui-Pędowska Architekci - O nas"
        description="Pracownia powstała z inicjatywy Mai Bui Ngoc oraz Marty Pędowskiej"
      />
      <section className="container section-container section-with-nav">
        <h2 className="col-xs-12 center section-with-nav-content">Pracownia</h2>
        <div className="col-xs-12 col-md-10 col-md-offset-1">
          <MainText className="big-text">{aboutPageData.main_text}</MainText>
        </div>
      </section>
      {/* <section className="container section-container">
        <div className="row reverse">
          <div
            className="col-xs-12 col-md-6 col-md-offset-1 col-lg-6"
            ref={addToSectionsRefs}
          >
            <SquereImg
              src={aboutPageData.mai.image}
              alt="Zdjęcie Mai Bui Ngoc"
              imgBigDivider={2}
            />
          </div>
          <div className="col-xs-12 col-md-5">
            <h2>{aboutPageData.mai.name}</h2>
            <p> {aboutPageData.mai.text}</p>
          </div>
        </div>
      </section> */}
      <section className="container section-container">
        <div className="row start-md">
          {/* <div className="col-xs-12 col-md-6 col-lg-6" ref={addToSectionsRefs}>
            <SquereImg
              src={aboutPageData.marta.image}
              alt="Zdjęcie Marty Pędowskiej"
              imgBigDivider={2}
            />
          </div> */}
          <div className="col-xs-12 col-md-5">
            <h2>{aboutPageData.mai.name}</h2>
            <p> {aboutPageData.mai.text}</p>
          </div>
          <div className="col-xs-12 col-md-5 col-md-offset-2">
            <h2>{aboutPageData.marta.name}</h2>
            <p> {aboutPageData.marta.text}</p>
          </div>
        </div>
      </section>
      <section className="container section-container">
        <h2 className="col-xs-12 center">Współpracujemy</h2>
        <div className="row col-md-10 col-md-offset-1">
          {aboutPageData.cooperate.map(({ name, role }) => (
            <BorderContainerBox className="col-xs-12 col-md-6" key={name}>
              <CooperatorName>{name}</CooperatorName>
              <CooperatorRole className="additional-info">
                {role}
              </CooperatorRole>
            </BorderContainerBox>
          ))}
        </div>
      </section>
      <ContactSection />
    </Layout>
  )
}

export default AboutPage
