import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import gsap from "gsap"

import SEO from "../components/seo"
import CrossIcon from "../components/crossIcon"
import ResponsiveImage from "../components/ResponsiveImage"

import { BorderContainerBox } from "../pages/o_nas"
import { MAX_WIDTH, WIDTH_PERCENT } from "../pages/index"

const ProjectContainer = styled.main`
  width: 100%;
  position: relative;
  overflow: hidden;
`

const ContentContainer = styled.section`
  max-width: ${() => (MAX_WIDTH / 12) * 7}px;

  @media only screen and (min-width: 62em) {
    margin: 5rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: calc(100vh - 5rem);
    height: 100%;
    position: fixed;
    left: 50%;
    right: ${() => `calc((1 - ${WIDTH_PERCENT}) / 2 * 100vw)`};
    top: 0;
    overflow: auto;
  }

  @media only screen and (min-width: 75em) {
    left: calc(100vw / 12 * 5);
  }
`
const PlanImageContainer = styled.div`
  text-align: center;
`

const PlanImage = styled.img`
  max-width: 100%;
  max-height: 20rem;
  margin: auto;
  cursor: pointer;
`

const ImgsContainer = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: 50vh;
  overflow: auto;

  img {
    height: 100%;
  }

  @media screen and (orientation: landscape) {
    height: 100vh;
  }

  @media only screen and (min-width: 62em) {
    height: auto;
    flex-direction: column;
    width: ${() => `calc(100% - ((1 - ${WIDTH_PERCENT}) / 2) * 100vw)`};
    margin-left: 2.5vw;

    img {
      width: 100%;
      height: auto;

      &:not(:last-child) {
        margin-bottom: calc(132px + calc(var(--base-grid-gutter) / 2));
      }
    }
  }
`

const StyledAniLink = styled(AniLink)`
  opacity: 1;
  font-size: 1.6rem;
  width: 50%;
  padding: 2rem;
  text-align: left;

  & + a {
    margin-left: 0;
  }

  @media only screen and (min-width: 62em) {
    position: fixed;
    left: 0;
    top: 100%;
    font-size: 1.6rem;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    height: 2.5vw;
    width: 100vh;
    text-align: center;
    line-height: 2.5vw;
    padding: 0;
  }
`

const NextStyledAniLink = styled(StyledAniLink)`
  text-align: right;
  margin-left: 50%;

  @media only screen and (min-width: 62em) {
    left: 100%;
    top: 0;
    transform: rotate(90deg);
    text-align: center;
    margin-left: 0;
  }
`

const Info = styled.p`
  color: var(--black);
  margin-bottom: 0;
`
const InfoName = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
`

const StyledCloseLink = styled(AniLink)`
  position: fixed;
  right: 2.5vw;
  top: 5rem;
  transition: all ease-in-out 0.2s;
  z-index: 1;

  &:hover {
    transform: rotate(180deg) scale(0.9);
  }

  svg {
    width: 4rem;
    height: 4rem;
  }
`
const removeEventListener = () => {
  window.removeEventListener("popstate", unmountClean)
}

const unmountClean = () => {
  gsap.set("body", { maxHeight: "100vh", overflow: "hidden" })
  removeEventListener()
}

const CloseLink = ({ pathname = "" }) => {
  return (
    <StyledCloseLink
      onClick={unmountClean}
      to={pathname || "/projekty"}
      cover
      direction="up"
      bg="#fdfdfe"
    >
      <CrossIcon />
    </StyledCloseLink>
  )
}

const ProjectPost = ({ data, location }) => {
  const {
    markdownRemark: { frontmatter },
    next,
    previous,
  } = data
  const planRef = useRef(null)
  const [planAnimation, setPlanAnimation] = useState(null)

  useEffect(() => {
    gsap.set("body", { maxHeight: "unset", overflow: "auto" })

    if (planRef.current) {
      setPlanAnimation(
        gsap.to(planRef.current, {
          transformOrigin: "left bottom",
          scale: 3,
          zIndex: 2,
          marginLeft: 0,
          xPercent: -10,
          paused: true,
          ease: "power2",
        })
      )
    }

    window.addEventListener("popstate", unmountClean)
  }, [])

  const showBigPlan = () => {
    planAnimation.play()
  }

  const hideBigPlan = () => {
    planAnimation.reverse()
  }

  return (
    <ProjectContainer className="row">
      <SEO
        title={`Bui-Pędowska Architekci - projekt ${frontmatter.title.toLowerCase()}`}
        description={frontmatter.description}
      />
      <CloseLink
        pathname={
          location?.state?.previousPathname ||
          (typeof window !== "undefined" &&
            window?.sessionStorage.getItem("backUrl"))
        }
      />
      <div className="col-xs-12 col-md-6 col-lg-5 row">
        <ImgsContainer>
          <ResponsiveImage
            src={frontmatter.main_image}
            bigDivider={2}
            alt={`Główne zdjęcie projekty ${frontmatter.title.toLowerCase()}`}
          />
          {frontmatter.images.map(img => (
            <ResponsiveImage
              src={img}
              bigDivider={2}
              key={img}
              alt={`Zdjęcie projekty ${frontmatter.title.toLowerCase()}`}
            />
          ))}
        </ImgsContainer>
      </div>
      <ContentContainer className="row col-xs-12 col-md-6 col-lg-7">
        <div className="col-xs-12 col-md-11 col-md-offset-1 col-lg-10 col-lg-offset-2 row">
          <div className="col-xs-12 col-md-10">
            <h1 className="post-project-title">{frontmatter.title}</h1>
            <p>{frontmatter.description}</p>
          </div>
          <BorderContainerBox className="col-xs-12">
            {frontmatter.plan && (
              <PlanImageContainer className="col-xs-4">
                <PlanImage
                  onMouseEnter={showBigPlan}
                  onTouchStart={showBigPlan}
                  onMouseLeave={hideBigPlan}
                  onTouchEnd={hideBigPlan}
                  ref={planRef}
                  src={frontmatter.plan}
                  alt={`Plan rozkładu pomieszczeń ${frontmatter.title}`}
                />
              </PlanImageContainer>
            )}
            {frontmatter.info && (
              <div className="col-xs-8 row">
                <div className="col-xs-6">
                  <InfoName className="additional-info">Lokalizacja</InfoName>
                  <Info className="additional-info">
                    {frontmatter.localization}
                  </Info>
                </div>
                <div className="col-xs-6">
                  <InfoName className="additional-info">
                    Powierzchnia użytkowa
                  </InfoName>
                  <Info className="additional-info">
                    {frontmatter.info.area}
                  </Info>
                </div>
                <div className="col-xs-6">
                  <InfoName className="additional-info">Inwestor</InfoName>
                  <Info className="additional-info">
                    {frontmatter.info.investor}
                  </Info>
                </div>
                <div className="col-xs-6">
                  <InfoName className="additional-info">Rok powstania</InfoName>
                  <Info className="additional-info">
                    {frontmatter.info.year} rok
                  </Info>
                </div>
                <div className="col-xs-6">
                  <InfoName className="additional-info">Projektanci</InfoName>
                  {frontmatter.info.designers.map(designer => (
                    <Info className="additional-info" key={designer.name}>
                      {designer.name}
                    </Info>
                  ))}
                </div>
                <div className="col-xs-6">
                  <InfoName className="additional-info">Typ</InfoName>
                  <Info className="additional-info">
                    {frontmatter.info.type}
                  </Info>
                </div>
              </div>
            )}
          </BorderContainerBox>
        </div>
        {previous && (
          <StyledAniLink
            to={previous.fields.slug}
            className="navigation"
            cover
            direction="right"
            bg="#fdfdfe"
            onClick={unmountClean}
          >
            {previous.frontmatter.title}
          </StyledAniLink>
        )}
        {next && (
          <NextStyledAniLink
            to={next.fields.slug}
            className="navigation"
            cover
            direction="left"
            bg="#fdfdfe"
            onClick={unmountClean}
          >
            {next.frontmatter.title}
          </NextStyledAniLink>
        )}
      </ContentContainer>
    </ProjectContainer>
  )
}

export default ProjectPost

export const pageQuery = graphql`
  query ProjectPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        description
        localization
        images
        main_image
        plan
        title
        info {
          area
          designers {
            name
          }
          investor
          type
          year
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
